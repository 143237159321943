.CopyToClipBoard {
	position: absolute;
	top: 0;
	right: 0;
}

.CopyToClipBoard__btn {
	display: flex;
	align-items: center;
	-webkit-appearance: none;
	border: none;
	background-color: transparent;
}

.CopyToClipBoard__btn svg {
	width: 1rem;
	height: 1rem;
}

.CopyToClipBoard__notify {
    position: fixed;
    z-index: 99;
    bottom: 20px;
    background-color: #000;
    padding: 15px 35px;
    left: 50%;
    transform: translate(-50%, 500%);
    transition: all .3s ease-in-out;
}

.CopyToClipBoard__notify--active {
	transform: translate(-50%, 0);
}

.CopyToClipBoard__text {
    color: #fff;
    display: block;
    line-height: 1rem;
}
