.Snippet {
	&__title {
		color: rgb(26, 13, 171);
	    font-size: 20px;
	    line-height: 1.3;
	    font-weight: normal;
	    display: inline-block;
	    max-width: 600px;
	    vertical-align: top;
	    text-overflow: ellipsis;
	    text-decoration: none;
	    margin: 0px;
	    overflow: hidden;
	}

	&__slug {
		font-size: 16px;
	    color: rgb(0, 102, 33);
	    margin-top: .5rem;
	}

	&__desc {
		color: rgb(118, 118, 118);
	    cursor: pointer;
	    position: relative;
	    max-width: 600px;
	    padding-top: 1px;
	    font-size: 14px;
	    line-height: 1.57;
	}
}