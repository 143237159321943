.seo {
	&__progress {
		width: 100%;
		height: 8px;
		margin-top: 8px;
		-webkit-appearance: none;
	}

	&__progress::-webkit-progress-bar {
		background-color: #ddd;
		border-color: #ddd;
		-webkit-appearance: none;
		transition: all .25s ease;
	}

	&__progress::-webkit-progress-value {
		-webkit-appearance: none;
	}

	&__progress--low::-webkit-progress-value {
		background-color: #e1090a;
	}

	&__progress--medium::-webkit-progress-value {
		background-color: #f37c00;
	}

	&__progress--good::-webkit-progress-value {
		background-color: #4fac31;
	}
}