.Collapse {
    &__heading {
        cursor: pointer;
    }

    &__heading-title {
        font-size: 1rem;
    }

    &__heading-icon svg {
        transition: transform .25s ease;
    }

    &__heading-icon--show svg {
        transform: rotate(180deg);
    }

    &__content {
        overflow: hidden;
        transition: max-height .25s ease;
    }
    
    &__content--hide {
        max-height: 0;
    }
    
    &__content--show {
        max-height: 500px;
    }
}
